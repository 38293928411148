import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <main>
        <h1 class="App-title">Tiago Dória</h1>
        <div className="grid">
            <p>Tiago Dória é envolvido com internet desde 1995. Ele codesenvolveu um dos primeiros jornais online feitos por estudantes no Brasil.</p>
            <p>Dória é também conhecido por criar e editar por sete anos um dos <a href="https://www.tiagodoria.com.br/blog">primeiros blogs sobre cultura digital</a>, o qual se tornou uma das principais referências sobre internet em língua portuguesa. Devido a esse blog, Dória conquistou prêmios nacionais e internacionais e foi convidado a assinar uma coluna sobre tecnologia na MTV Brasil, ser "profissional convidado" da PopTech e membro do júri do prêmio Students Awards da CNN.</p>
            <p>Desde 2013, Dória tem se dedicado ao aspecto técnico da internet. Nos últimos sete anos, ele tem trabalhado com empresas e startups em Boston, EUA, no desenvolvimento e na implementação de estratégias envolvendo frontend, conteúdo, produto e experiência do usuário (UX).</p>
            <p>Dória possui certificação em Estratégia e Inovação pelo <a href="http://www.mit.edu">MIT</a> e mestrado em Experiência do Usuário e Desenvolvimento Web pela <a href="https://www.northeastern.edu/">Northeastern University</a>, ambos em Boston, Massachusetts, EUA. É membro da ACM - Association for Computing Machinery (Global e Boston).</p> 
            <p>contato: <a href="mailto:tiago@tiagodoria.dev">tiago@tiagodoria.dev</a></p>
        </div>
        <div className="grid">
          <h2>english version</h2>
          <p>Tiago Doria has been involved with the internet since 1995. He co-developed one of the first Brazilian student online newspapers.</p>
          <p>Doria is also known for creating one of the first blogs about digital culture. During seven years, he edited the blog that became one of the main references for the internet in Portuguese. Because of his blog, he got international awards and was invited to work for MTV, be a "guest blogger" of PopTech and a member of the jury of the CNN Student Awards.</p>
          <p>Since 2013, Doria is more involved with the technical aspect of the internet. In the last seven years, he has worked with startups/companies in Boston, developing and implementing frontend, product and UX strategies.</p>
          <p>Doria is certified in Strategy and Innovation at MIT and holds a Master of UX and Web Development from Northeastern University, Boston, Massachusetts, USA. He is also a member of the ACM - Association for Computing Machinery - Boston Chapter.</p> 
          <p>contact: <a href="mailto:tiago@tiagodoria.dev">tiago@tiagodoria.dev</a></p>
        </div>
      </main>



      <style jsx>{`
        .container {
          min-height: 100vh;
          padding: 0 0.5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        main {
          padding: 5rem 0;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: left;
        }

        footer {
          width: 100%;
          height: 100px;
          border-top: 1px solid #eaeaea;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        footer img {
          margin-left: 0.5rem;
        }

        footer a {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        a {
          color: #0070f3;
          text-decoration: none;
        }

        .title a {
          color: #2B2B88;
          text-decoration: none;
        }

        .title a:hover,
        .title a:focus,
        .title a:active {
          text-decoration: underline;
        }

        .title {
          margin: 0;
          line-height: 1.15;
          font-size: 4rem;
          color: #2B2B88;
        }

        .title,
        .description {
          text-align: center;
        }

        .description {
          line-height: 1.5;
          font-size: 1.5rem;
        }

        code {
          background: #fafafa;
          border-radius: 5px;
          padding: 0.75rem;
          font-size: 1.1rem;
          font-family: Menlo, Monaco, Lucida Console, Liberation Mono,
            DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
        }

        .grid {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;

          max-width: 800px;
          margin-top: 3rem;
          padding: 0 1rem;
        }

        .grid p {
          font-size: 1.2rem;
          line-height: 1.5;
        }


        .grid h2 {
          font-size: 1rem;
          font-style: italics;
        }

        .card {
          margin: 1rem;
          flex-basis: 45%;
          padding: 1.5rem;
          text-align: left;
          color: inherit;
          text-decoration: none;
          border: 1px solid #eaeaea;
          border-radius: 10px;
          transition: color 0.15s ease, border-color 0.15s ease;
        }

        .card:hover,
        .card:focus,
        .card:active {
          color: #0070f3;
          border-color: #0070f3;
        }

        .card h3 {
          margin: 0 0 1rem 0;
          font-size: 1.5rem;
        }

        .card p {
          margin: 0;
          font-size: 1.25rem;
          line-height: 1.5;
        }

        @media (max-width: 600px) {
          .grid {
            width: 100%;
            flex-direction: column;
            padding: 0 16px;
          }
        }
      `}</style>

      <style jsx global>{`
        html,
        body {
          padding: 0;
          margin: 0;
          font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
            Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
            sans-serif;
        }

        * {
          box-sizing: border-box;
        }
      `}</style>
    </div>
  );
}

export default App;
